import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AuthRoutes, AuthServiceConfig, EndpointsContext, EndpointsProvider } from '@docsys/auth';
import { Id } from '@docsys/common-types';

import { AuthConfigurationProps } from './types';

/**
 * Custom auth configuration which gets tenant id from parameter instead of looking up
 * in the configuration server based on a hostname.
 * @param props The custom auth configuration.
 */
export const AuthConfiguration: React.FC<AuthConfigurationProps> = (props: AuthConfigurationProps) => {
	const {
		appId,
		configUrl,
		children,
		tenantId,
		error: ErrorComponent,
		loading: LoadingComponent,
	} = props;

	const { tenantId: route } = useParams();

	return	<EndpointsProvider
		configUrl={configUrl}
		error={ErrorComponent}
		loading={LoadingComponent}
		tenantId={tenantId as Id}
	>
		<EndpointsContext.Consumer>
			{
				(endpoints) => {
					if (!endpoints.initialized) {
						return (LoadingComponent && <LoadingComponent/>) || null;
					}

					if (route === 'auth') {
						return <AuthServiceConfig
							appId={appId as Id}
							authUrl={endpoints.endpoints.auth}
						>
							<AuthRoutes error={ErrorComponent} loading={LoadingComponent}/>
						</AuthServiceConfig>;
					} else {
						return <AuthServiceConfig
							appId={appId as Id}
							authUrl={endpoints.endpoints.auth}
						>
							<>{children}</>
						</AuthServiceConfig>;
					}
				}
			}
		</EndpointsContext.Consumer>
	</EndpointsProvider>
}
