import * as React from 'react';

import { Theme } from '@docsys/theme';

import { env } from '../helpers/env';

export const AppTheme: React.FC = (props) => {
	const {
		children,
	} = props;

	return <>
		<Theme
			darkMode={'light'}
			cdnUrl={env.cdnUrl}
			style={'blauw'}
		/>
		{children}
	</>;
};
