import { Id } from '@docsys/common-types';

declare let process : {
	env: Record<string, any>
}

const variables = process.env;

function get<T>(key: string): T {
	if (variables[key] === null) {
		throw new Error('Required environment variable "' + key + '" not set.');
	}

	return variables[key] as unknown as T;
}

export const env = {
	appId: get<Id>('APP_ID'),
	configUrl: get<string>('CONFIG_URL'),
	cdnUrl: get<string>('CDN_URL'),
};
