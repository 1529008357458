import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from '@docsys/controls';

import { NotFoundErrorPage } from './../pages/error/not-found';
import { Home } from './../pages/home';
import { Users } from './../pages/users/index';

export const AppRoutes: React.VFC = () => {
	return <ErrorBoundary>
		<Routes>
			<Route path="" element={<Home/>} />
			<Route path=":tenantId/*" element={<Users/>} />
			<Route path="*" element={<NotFoundErrorPage/>} />
		</Routes>
	</ErrorBoundary>;
};
