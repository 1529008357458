import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Page } from '@docsys/controls';

import { AppRoutes } from './app-routes';
import { AppTheme } from './app-theme';

export const App: React.VFC = () => {
	return <Page>
		<Router>
			<AppTheme>
				<AppRoutes/>
			</AppTheme>
		</Router>
	</Page>;
};
