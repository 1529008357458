import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Auth } from '@docsys/auth';
import { Loading, LoadingVariant } from '@docsys/controls';
import { useLocalStorage } from '@docsys/hooks';


import { env } from '../../helpers/env';
import { AuthConfiguration } from './auth-configuration';

import { UserInfo } from './user-info';

export const Users: React.VFC = () => {
	const { tenantId: newTenantId } = useParams();
	const [ tenantId, setTenantId ] = useLocalStorage<string>('tenantId.' + window.location.host, '');

	React.useEffect(() => {
		if (!newTenantId || newTenantId == 'auth') {
			return;
		}
		setTenantId(newTenantId);
	}, [newTenantId]); // eslint-disable-line react-hooks/exhaustive-deps -- set tenant id

	return <AuthConfiguration
		appId={env.appId}
		configUrl={env.configUrl}
		tenantId={tenantId ?? ''}
	>
		<Auth
			allowRedirect
			error={() => <Loading label={'Laden...'} variant={LoadingVariant.Large} />}
			loading={() => <Loading label={'Laden...'} variant={LoadingVariant.Large} />}
			scopes={'profile'}
		>
			<UserInfo/>
		</Auth>
	</AuthConfiguration>
};
