import * as React from 'react';

import { AuthContext } from '@docsys/auth';
import { Button, ButtonSet, ButtonType, Content, H1, H3, LinkButtonPriority } from '@docsys/controls';

/**
 * Component that shows information of the logged in user.
 */
export const UserInfo: React.VFC = () => {
	const [sub, setSub] = React.useState<string>('');

	const authContext = React.useContext(AuthContext);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const d: any = Object.assign({}, authContext);
			delete d.service;

			setSub(d?.claims?.name ?? 'User not found');
		};

		asyncEffect();
	}, [authContext]);

	const onLogout = () => {
		if (authContext.initialized) {
			authContext.service.signOut({returnUrl: window.location.host});
		}
	};

	return <Content>
		<H1 first>Authorization Server</H1>
		<hr/>
		<p>You are signed in as:</p>
		<H3>{sub}</H3>
		<hr/>
		<p>Please ask your application administrator to configure a valid redirect url or manually navigate back to your application.</p>
		<hr/>
		<ButtonSet>
			<Button
				label={'Uitloggen'}
				type={ButtonType.Button}
				priority={LinkButtonPriority.Secondary}
				onClick={onLogout}/>
		</ButtonSet>
	</Content>
};
