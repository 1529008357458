import * as React from 'react';

import { Content, H1 } from '@docsys/controls';

import './index.scss';

export const Home: React.VFC = () => {

	return <Content>
		<H1 first>Authorization Server</H1>
		<hr/>
	</Content>;
};
